
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-behavior: smooth;
}

@layer base {
    html {
      scroll-behavior: smooth;
    }
  }